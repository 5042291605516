<template>
  <div>
    <div class="origin-table-container">
      <div class="table">
        <a-table
          class="m_table_cust origin-table"
          size="middle"
          :pagination="false"
          :rowKey="(record, index) => index"
          :data-source="tableList"
          tableLayout="fixed"
          :loading="isLoading"
          bordered
        >
          <!-- 日期 -->
          <a-table-column align="left" dataIndex="date" title="日期" width="200px"> </a-table-column>
          <!-- 审核人 -->
          <a-table-column v-if="showAudit" align="left" dataIndex="username" title="审核人" width="200px">
          </a-table-column>
          <!-- 素材包 -->
          <a-table-column v-if="showPackage" align="left" dataIndex="packageName" title="素材包" width="200px">
          </a-table-column>
          <!-- 送审量 -->
          <a-table-column align="left" dataIndex="totalNum" title="送审量" width="200px"> </a-table-column>
          <!-- 完审率 -->
          <a-table-column align="left" dataIndex="auditRatio" title="完审率" width="200px">
            <div slot-scope="text, record">{{ record.auditRatio }}%</div>
          </a-table-column>
          <!-- 审核通过率 -->
          <a-table-column align="left" dataIndex="passRatio" title="审核通过率" width="200px">
            <div slot-scope="text, record">{{ record.passRatio }}%</div>
          </a-table-column>
        </a-table>
      </div>
      <a-pagination
        class="pagination"
        v-if="tableList?.length > 0"
        show-size-changer
        :current="query.page"
        :total="total"
        :show-total="(total) => `共 ${total} 条`"
        @change="handleCurrentPage"
        @showSizeChange="handleChangePageSize"
        :pageSize="query.limit"
      />
      <m-empty v-if="tableList?.length === 0" style="height: 500px">
        <template #description>暂无数据 </template>
      </m-empty>
    </div>
  </div>
</template>

<script>
import { preAuditReport } from '@/api/originLity'
export default {
  name: 'OriginTable',
  props: {
    arr: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isLoading: false,
      // 审核人列表
      showAudit: false,
      // 素材包列表
      showPackage: false,
      total: undefined,
      query: {
        page: 1,
        limit: 10
      },
      tableList: []
    }
  },
  methods: {
    // table列表数据
    async getTabList () {
      this.isLoading = true
      const { userId, auditUsers, beginDate, endDate, packageIdList } = this.arr
      const param = { userId, packageIdList, auditUsers, beginDate, endDate, ...this.query }
      const { data = [] } = await preAuditReport(param)
      this.tableList = data.items
      this.total = data.total
      // 审核列表
      auditUsers.length > 0 ? (this.showAudit = true) : (this.showAudit = false)
      // 素材包列表
      packageIdList.length > 0 ? (this.showPackage = true) : (this.showPackage = false)
      this.isLoading = false
    },
    // 分页
    handleCurrentPage (page) {
      this.query.page = page
      this.getTabList()
    },
    handleChangePageSize (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getTabList()
    }
  }
}
</script>

<style lang="less" scoped>
.origin-table-container {
  margin-top: 10px;
  border-radius: 5px;
  background: #fff;
  .table {
    display: flex;
    padding: 20px;
    .origin-table {
      overflow: hidden;
      width: 100%;
      position: relative;
    }
    .ant-table-fixed-left table,
    .ant-table-fixed-right-right table {
      width: min-content;
    }
  }
  .pagination {
    padding: 10px 10px;
  }
}
</style>
