<template>
  <div class="originalityApprove">
    <!-- head -->
    <div class="head">
      <div class="application_top">
        <div
          v-for="(item, itemIndex) in advertisingTab"
          :key="item.key"
          :class="['top_item', queryData.auditSts === item.key ? 'act' : '']"
          @click="handleChangeTabs(item, itemIndex)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="close-btn">
        <span
          v-if="creativeAuditSts === 'A' && role !== 'SUPER_ADMIN'"
          style="margin-right: 10px; line-height: 32px"
        >审核权限</span
        >
        <a-popconfirm
          title="确认关闭审核权限?"
          ok-text="是"
          cancel-text="否"
          v-if="creativeAuditSts === 'A' && role !== 'SUPER_ADMIN'"
          @confirm="handleChangeAuditSTS"
        >
          <a-switch :checked="creativeAuditSts === 'A'" />
        </a-popconfirm>
      </div>
    </div>
    <!-- search -->
    <a-form-model :model="searchFormData" class="originalitySerachForm">
      <a-select
        v-if="role === 'SUPER_ADMIN' || (adminUserId != null && adminUserId == 1)"
        style="width: 180px"
        class="m-r-10 m-b-20"
        :allowClear="false"
        v-model="searchFormData.userId"
        @change="changeUser"
        placeholder="请选择公司"
      >
        <a-select-option v-for="item in userByCreativeOptions" :key="item.id" :value="item.id">
          {{ item.name }}
        </a-select-option>
      </a-select>
      <m-select-more
        v-if="+queryData.auditSts !== 3"
        class="m-r-10 m-b-20"
        v-model="searchFormData.platIdList"
        :allData="platformOptions"
        :searchById="true"
        :hasIcon="true"
        style="width: 180px"
        :showId="false"
        :showLabel="false"
        label="广告平台"
      />
      <m-select-more
        v-if="+queryData.auditSts !== 3"
        class="m-r-10 m-b-20"
        v-model="searchFormData.positionIdList"
        :allData="positionListOptions"
        :searchById="true"
        :hasIcon="true"
        style="width: 180px"
        :showId="false"
        :showLabel="false"
        label="广告样式"
      />
      <a-select
        v-if="+queryData.auditSts !== 3"
        class="m-r-10 m-b-20"
        showSearch
        style="width: 180px"
        allowClear
        v-model="searchFormData.type"
        placeholder="请选择素材类型"
      >
        <a-select-option v-for="pitem in sourceTypeOPtions" :key="pitem.id" :value="pitem.value">
          {{ pitem.label }}
        </a-select-option>
      </a-select>
      <m-select-more
        v-if="queryData.auditSts !== '0'"
        class="m-r-10 m-b-20"
        style="width: 220px"
        v-model="searchFormData.auditUsers"
        :allData="auditUserOptions"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        width="450px"
        label="审核人员"
      />
      <m-select-more
        v-if="+queryData.auditSts === 3"
        class="m-r-10 m-b-20"
        style="width: 220px"
        v-model="searchFormData.packageIdList"
        :allData="tabpackageList"
        :searchById="false"
        :hasIcon="false"
        :showId="false"
        width="450px"
        label="素材包"
      />
      <div
        :style="'display: flex; width:' + (+queryData.auditSts !== 3 ? '315px' : '230px')"
        class="dateBox m-r-20 m-b-20"
      >
        <span v-if="+queryData.auditSts !== 3" style="display: inline-block; width: 75px">{{
          +queryData.auditSts == 0 ? '上传时间' : '审核时间'
        }}</span>
        <a-range-picker
          style="width: 100%"
          format="YYYY-MM-DD"
          :disabledDate="disabledDate"
          :value="rangePickerDate"
          @change="handleChangeTime"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
          :placeholder="['Start', 'End']"
        ></a-range-picker>
      </div>
      <a-input
        v-if="+queryData.auditSts !== 3"
        class="m-r-10"
        style="width: 180px"
        allowClear
        v-model.trim="searchFormData.keyWords"
        placeholder="标题、描述、标签"
      >
      </a-input>
      <a-button type="primary" @click="handleSerach">搜索</a-button>
    </a-form-model>
    <!-- table list -->
    <template v-if="+queryData.auditSts !== 3">
      <a-spin :spinning="spinLoading" class="originalityContent">
        <!-- 素材包列表 -->
        <div class="packageBox">
          <div class="packageTitle">
            <span>素材包</span>
            <span
              class="zidingyi"
              @click="clickMaterial"
              v-if="showPlat === '1' && queryData.auditSts === '0'"
            >自定义素材包</span
            >
          </div>
          <a-radio-group v-model="searchFormData.packageId">
            <a-radio
              v-for="item in arrList"
              :value="item.id"
              :key="item.id"
              :class="{ adtype_radio_item: true, active: searchFormData.packageId === item.id }"
              @click="handleCLick(item, item.id)"
            >
              <div class="adtype_radio_box">
                <div>{{ item.name }}</div>
                <div>素材数：{{ item.creativeNum }}</div>
                <div class="btnBox">
                  <p size="small" v-if="showPlat === '1' && item.type !== 2" @click.stop="handleClickUpdate(item)">
                    编辑
                  </p>
                  <p size="small" v-if="item.type === 3" @click.stop="handleClickDel(item)">删除</p>
                </div>
              </div>
            </a-radio>
          </a-radio-group>
        </div>
        <div v-if="tableData.length > 0" class="tableDataBox">
          <a-row type="flex" :gutter="20">
            <a-col
              v-for="(item, index) in tableData"
              :key="item.id"
              class="imgItemBox"
              style="margin-bottom: 25px; width: 230px; height: 15%"
            >
              <a-card
                class="items"
                style="width: 100%; position: relative; height: 100%"
                :bodyStyle="{ padding: '15px 6px' }"
              >
                <!-- 事件只在点击图片和背景时候选中 -->
                <div
                  slot="cover"
                  v-if="item.type == 1"
                  class="position-r imageCoverStyle"
                  @click="CheckTableItem(item)"
                >
                  <el-image
                    style="width: 100%"
                    alt="加载失败"
                    object-fil="cover"
                    :src="item.creativeUrl"
                    :ref="'previewImg' + index"
                  >
                  </el-image>
                  <div class="operationBtn">
                    <span
                      class="custBtnSpan"
                      @click.stop="(e) => handleChangeAudit(e, 'batch-item-detail', item)"
                    >详情</span
                    >
                    <span
                      class="custBtnSpan"
                      v-if="['0', '2'].includes(queryData.auditSts)"
                      @click.stop="(e) => handleChangeAudit(e, 'batch-item-success', item)"
                    >通过</span
                    >
                    <span
                      class="custBtnSpan"
                      v-if="['0', '1'].includes(queryData.auditSts)"
                      @click.stop="(e) => handleChangeAudit(e, 'batch-item-refuse', item)"
                    >拒绝</span
                    >
                  </div>
                </div>
                <div slot="cover" v-if="item.type == 2" class="position-r imageCoverStyle">
                  <VideoPreview :videoList="[item]"></VideoPreview>
                  <div class="operationBtn">
                    <span
                      class="custBtnSpan"
                      @click="(e) => handleChangeAudit(e, 'batch-item-detail', item)"
                    >详情</span
                    >
                    <span
                      class="custBtnSpan"
                      v-if="['0', '2'].includes(queryData.auditSts)"
                      @click="(e) => handleChangeAudit(e, 'batch-item-success', item)"
                    >通过</span
                    >
                    <span
                      class="custBtnSpan"
                      v-if="['0', '1'].includes(queryData.auditSts)"
                      @click="(e) => handleChangeAudit(e, 'batch-item-refuse', item)"
                    >拒绝</span
                    >
                  </div>
                </div>
                <div class="imgfullscreen">
                  <!-- 图片只在放大按钮后可以预览 -->
                  <a-icon type="fullscreen" @click="handleFullscreen(item, index)" />
                  <!-- 图片覆盖掉icon，相当于点击图片 -->
                  <el-image
                    v-if="+item.type === 1"
                    object-fil="cover"
                    :src="item.creativeUrl"
                    :ref="'previewImg' + index"
                    :preview-src-list="[item.creativeUrl]"
                  >
                  </el-image>
                </div>
                <a-checkbox class="imgCheckbox" v-model="item.isChecked" @change="handleCheckTableItem"></a-checkbox>
                <a-card-meta>
                  <template slot="description">
                    <div style="font-size: 12px">
                      <span class="grayWord">{{ showText(item) }}</span>
                    </div>
                    <a
                      v-if="item.downloadUrl || item.landingPage"
                      target="_blank"
                      :href="item.downloadUrl || item.landingPage || '#'"
                      class="text-ellipsis m-t-10"
                    >
                      <a-icon type="link" />
                      {{ item.downloadUrl || item.landingPage }}
                    </a>
                    <a v-else class="text-ellipsis m-t-10">
                      <a-icon type="link" />
                      {{ '--' }}
                    </a>
                  </template>
                </a-card-meta>
              </a-card>
            </a-col>
          </a-row>
          <!-- 分页 -->
          <a-pagination
            class="pagination"
            v-if="tableData.length > 0"
            show-size-changer
            :current="queryData.page"
            :total="total"
            :show-total="(total) => `共 ${total} 条`"
            @change="handleCurrentPage"
            @showSizeChange="handleChangePageSize"
            :page-size-options="['30', '60', '100', '300']"
            :pageSize="queryData.limit"
          />
        </div>
        <m-empty v-if="tableData.length == 0" style="height: 40vh">
          <template #description>暂无数据</template>
        </m-empty>
      </a-spin>
      <div class="m-b-10 fixedButton">
        <div class="fixed-content">
          <!-- <span class="m-r-20 font-title">待审核素材共有{{ total }}张</span> -->
          <a-checkbox
            v-model="allChecked"
            class="m-r-20"
            style="margin-right: 47px"
            @change="handleCheckAll"
          >全选</a-checkbox
          >
          <a-button
            v-if="queryData.auditSts !== '1'"
            :type="!(tableData.filter((item) => item.isChecked == true).length !== 0) ? '' : 'cust'"
            class="m-r-10"
            :disabled="!(tableData.filter((item) => item.isChecked == true).length !== 0)"
            @click="(e) => handleChangeAudit(e, 'batch-success')"
          >批量通过</a-button
          >
          <a-button
            class="m-r-10"
            v-if="queryData.auditSts !== '2'"
            :disabled="!(tableData.filter((item) => item.isChecked && item).length !== 0)"
            @click="(e) => handleChangeAudit(e, 'batch-refuse')"
          >批量拒绝</a-button
          >
          <a-button
            v-if="queryData.auditSts === '0'"
            :type="!(tableData.filter((item) => item.isChecked && item).length !== 0) ? '' : 'primary'"
            :disabled="!(tableData.filter((item) => item.isChecked && item).length !== 0)"
            @click="(e) => handleChangeAudit(e, 'batch-success-refuse')"
          >批量通过并拒绝未选</a-button
          >
        </div>
      </div>
    </template>
    <template v-if="+queryData.auditSts === 3">
      <OriginTable :arr="searchFormData" ref="table"></OriginTable>
    </template>
    <!-- 详情的弹框 -->
    <a-modal
      title="素材详情"
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      :width="1000"
      @cancel="handleCancel"
    >
      <div style="display: flex">
        <div class="flex-column-center" style="flex: 1">
          <img
            v-if="detailForm.type == 1"
            alt="加载失败"
            style="width: 100%"
            object-fil="cover"
            :src="detailForm.creativeUrl"
          />
          <video
            v-if="detailForm.type == 2"
            width="100%"
            height="100%"
            object-fit="fill"
            controls="false"
            disablePictureInPicture="true"
            noremote
            :autoplay="false"
            :loop="true"
            footbar
            nofullscreen
            noremoteplayback
            noplaybackrate
            controlslist="nodownload"
            name="media"
          >
            <source :src="detailForm.creativeUrl" type="video/mp4" />
          </video>
        </div>
        <div style="flex: 2">
          <a-form-model :form="detailForm" v-bind="layout">
            <a-form-model-item label="素材ID" class="m-b-0">
              <span>{{ detailForm.id || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item label="广告平台" class="m-b-0">
              <span>{{ detailForm.platName || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item label="素材标题" class="m-b-0">
              <span class="text-ellipsis-1">{{ detailForm.title || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item label="素材描述" class="m-b-0">
              <span class="text-ellipsis-1">{{ detailForm.description || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item label="下载地址" class="m-b-0 text-ellipsis-1">
              <a target="_blank" class="text-ellipsis-1" :href="detailForm.downloadUrl">{{
                detailForm.downloadUrl || '--'
              }}</a>
            </a-form-model-item>
            <a-form-model-item label="落地页" class="m-b-0">
              <a target="_blank" :href="detailForm.landingPage" class="text-ellipsis-1">{{
                detailForm.landingPage || '--'
              }}</a>
            </a-form-model-item>
            <a-form-model-item label="唤醒地址" class="m-b-0">
              <a target="_blank" :href="detailForm.deeplinkUrl" class="text-ellipsis-1">{{
                detailForm.deeplinkUrl || '--'
              }}</a>
            </a-form-model-item>
            <a-form-model-item v-if="queryData.auditSts === '0'" label="上传时间：" class="m-b-0">
              <span class="text-ellipsis-1">{{ detailForm.uploadTime?.replace('T', ' ') || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item v-else label="审核时间：" class="m-b-0">
              <span class="text-ellipsis-1">{{ detailForm.auditTime?.replace('T', ' ') || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item label="标签" class="m-b-0">
              <div class="labelBoxStyle">
                <a-textarea v-model="inputValue" placeholder="多个关键词以换行或逗号分隔" :rows="6" />
                <div class="add-btn">
                  <a-button type="primary" @click="handleInputConfirm">>></a-button>
                </div>
                <div class="tag-box">
                  <a-input v-model.trim="searchWord" placeholder="请输入搜索字段" />
                  <a-tag
                    :key="titem + tindex"
                    class="flexStyle text-ellipsis"
                    v-for="(titem, tindex) in showtags"
                    closable
                    @close="() => handleCloseTag(titem)"
                  >
                    <span class="text-ellipsis">{{ titem }}</span>
                  </a-tag>
                </div>
              </div>
            </a-form-model-item>
            <a-form-model-item label="审核人" class="m-b-0" v-if="+queryData.auditSts !== 0">
              <span class="text-ellipsis-1">{{ detailForm.auditUsername || '--' }}</span>
            </a-form-model-item>
            <a-form-model-item label="审核" class="m-b-0">
              <a-radio-group v-model="detailForm.auditSts">
                <a-radio value="1">通过</a-radio>
                <a-radio value="2">拒绝</a-radio>
              </a-radio-group>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </a-modal>
    <!-- 图片弹窗 -->
    <imgModel :visible="imgModelVisible" :data="imgModelData" @close="handleCloseImgModel"></imgModel>
    <!-- 自定义素材包弹窗 -->
    <materialPackage
      v-if="materialVisible"
      :appInfo="appInfos"
      :visible="materialVisible"
      :platArr="platArr"
      @modalCancel="materialVisible = false"
      :isAdd="isAdd"
      @changeAppInfo="changeAppInfo"
    />
    <!-- 提示确认弹窗 -->
    <a-modal
      v-model="popconfirmVisible"
      title="提示"
      :confirm-loading="confirmLoading"
      @ok="delHandleComfirm"
      @cancel="delHandleCancel"
    >
      <div class="batchModel">
        <a-icon class="icon" type="exclamation-circle" />
        <p>删除当前素材包，是否删除？</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {
  getCreativeList,
  audit,
  packageList,
  platformListForCpackage,
  deleteCpackage,
  updateTags,
  closeAudit
} from '@/api/creative'
import { getPositionList, getAuditUserList, getUserListByCreative } from '@/api/common'
import { mapGetters, mapState } from 'vuex'
import mixDate from '@/mixins/initDate'
import VideoPreview from '@/components/VideoPreview'
import { getList } from '@/api/originLity'
import imgModel from '../imgModel'
import OriginTable from './OriginTable'
import materialPackage from '../materialPackage'
import { getDuration } from '@/utils/duration'
export default {
  name: 'Originality',
  mixins: [mixDate],
  components: { VideoPreview, imgModel, materialPackage, OriginTable },
  // head() {
  //   return {
  //     meta: [
  //       { hid: 'description', 'http-equiv': 'Content-Security-Policy', content: 'upgrade-insecure-requests' }
  //     ]
  //   }
  // },
  data () {
    return {
      searchWord: '',
      searchFormData: {
        platId: undefined,
        positionId: undefined,
        keyWords: undefined,
        userId: undefined,
        packageIdList: undefined,
        auditUsers: [],
        packageId: undefined
      },
      allChecked: false,
      confirmLoading: false,
      visible: false,
      layout: {
        labelCol: { span: 3 },
        wrapperCol: { span: 18 }
      },
      queryData: {
        page: 1,
        limit: 30,
        auditSts: '0'
      },
      total: 0,
      platformOptions: [],
      tabpackageList: [],
      positionListAllOptions: [],
      positionListOptions: [],
      auditUserOptions: [],
      platArr: [],
      userByCreativeOptions: [],
      sourceTypeOPtions: [
        {
          label: '图片',
          value: 1
        },
        {
          label: '视频',
          value: 2
        }
      ],
      tableData: [],
      SUPER_ADMIN: false,
      inputValue: '',
      tags: [],
      allTags: [],
      detailForm: {},
      rangePickerDate: [],
      spinLoading: true,
      imgModelVisible: false,
      imgModelData: {},
      advertisingTab: [
        { name: '待审核', key: '0' },
        { name: '已通过', key: '1' },
        { name: '已拒绝', key: '2' },
        { name: '审核报表', key: '3' }
      ],
      arrList: [],
      isAdd: false,
      materialVisible: false,
      appInfos: {},
      popconfirmVisible: false,
      packageId: ''
    }
  },
  computed: {
    ...mapState({
      adminUserId: (state) => state.user.adminUserId,
      showPlat: (state) => state.user.showPlat,
      role: (state) => state.user.roles[0],
      creativeAuditSts: (state) => state.user.creativeAuditSts
    }),
    showtags () {
      const arr = []
      this.tags.forEach((item, index) => {
        if (item.includes(this.searchWord)) {
          arr.push(item)
        }
      })
      return arr
    },
    ...mapGetters(['adplanResourceUrl'])
  },
  watch: {},
  created () {
    const { endDate, startDate } = this.initDate(+this.queryData.auditSts === 3 ? 6 : 0, 0)
    this.searchFormData = {
      ...this.searchFormData,
      beginDate: startDate,
      endDate: endDate
    }
    this.rangePickerDate = [startDate, endDate]
    // 广告样式的下拉
    this.getPositionListFn()
    this.isCreat = true
    // 判断是否是超级管理员 || 超管创建的子账号 显示
    if (this.getIsAdmin()) this.getUserListByCreativeFn()
    // 获取素材包列表
    if (this.role !== 'SUPER_ADMIN' && this.adminUserId !== 1) {
      // 广告平台下拉
      this.getPlatformListFn('null')
      // 获取素材包列表
      this.role !== 'SUPER_ADMIN' && this.getPackageList()
    }
    // 审核人员下拉
    this.getAuditUserListFn()
    document.documentElement.scrollTop = 0
  },
  methods: {
    async handleChangeAuditSTS () {
      const resp = await closeAudit()
      if (resp.code === 200) {
        this.$store.dispatch('changeCreativeAuditSts', 'S')
        this.$router.push({
          path: '/accountInfo'
        })
      }
    },
    // 判断是否是超管及创建的子账户
    getIsAdmin () {
      return this.role === 'SUPER_ADMIN' || (this.adminUserId != null && this.adminUserId === 1)
    },
    // 修改用户
    changeUser () {
      // 审核列表时候,公司与素材包关联,其他时候,与广告平台关联
      this.queryData.auditSts === '3'
        ? this.getList(this.searchFormData.userId)
        : this.getPlatformListFn(this.searchFormData.userId)
    },
    // 审核列表-素材包列表
    async getList (id) {
      const param = this.getIsAdmin() ? id : undefined
      const { data = [] } = await getList({ userId: param })
      this.tabpackageList = data
    },
    // 取消删除
    delHandleCancel () {
      this.popconfirmVisible = false
    },
    // 删除素材包
    async delHandleComfirm () {
      const res = await deleteCpackage({ id: this.packageId })
      if (res.code === 200) {
        this.getPackageList()
        this.popconfirmVisible = false
      }
    },
    // 点击删除素材包
    handleClickDel (data) {
      this.packageId = data.id
      this.popconfirmVisible = true
    },
    // 获取素材包列表
    async getPackageList () {
      const { auditSts = '' } = this.queryData
      const {
        beginDate = '',
        endDate = '',
        auditUsers = '',
        keyWords = '',
        type = '',
        platIdList = [],
        positionIdList = []
      } = this.searchFormData
      const positionIdLists = []
      const platIdLists = []
      positionIdList.length > 0 &&
        positionIdList.map((item) => {
          positionIdLists.push(Number(item))
        })
      platIdList.length > 0 &&
        platIdList.map((item) => {
          platIdLists.push(Number(item))
        })
      const res = await packageList({
        beginDate,
        endDate,
        auditSts,
        platIdList: platIdLists,
        positionIdList: positionIdLists,
        type,
        keyWords,
        userId: this.getIsAdmin() ? Number(this.searchFormData.userId) : undefined,
        auditUsers: auditSts === '0' ? undefined : auditUsers
      })
      if (res.code === 200 && res.data) {
        this.arrList = res.data || []
        if (this.getIsAdmin()) {
          this.searchFormData.packageId = res.data[0]?.id || undefined
        } else {
          if (this.isCreat) this.searchFormData.packageId = res.data[0]?.id || undefined
        }
        this.isCreat = false
        this.getCreativeListFn({
          ...{ userId: Number(this.searchFormData.userId) },
          ...this.queryData,
          ...this.searchFormData
        })
      }
    },
    changeAppInfo () {
      this.spinLoading = true
      this.getPackageList()
    },
    showText (item) {
      if (item.title) {
        return item.title
      } else if (item.description) {
        return item.description
      } else {
        return '--'
      }
    },
    // 点击自定义素材包
    clickMaterial () {
      this.isAdd = true
      this.materialVisible = true
      this.platArr = this.platformOptions
    },
    // 点击素材包
    handleCLick (data, id) {
      this.searchFormData.packageId = id
      this.spinLoading = true
      this.getCreativeListFn({
        ...this.queryData,
        ...this.searchFormData
      })
    },
    // 点击素材包编辑
    handleClickUpdate (data) {
      this.isAdd = false
      this.materialVisible = true
      this.appInfos = data
      this.platArr = this.platformOptions
    },
    // 获取素材审核列表
    async getCreativeListFn (data) {
      const positionIdLists = []
      const platIdLists = []
      data.positionIdList.length > 0 &&
        data.positionIdList.map((item) => {
          positionIdLists.push(Number(item))
        })
      data.platIdList.length > 0 &&
        data.platIdList.map((item) => {
          platIdLists.push(Number(item))
        })
      data.positionIdList = positionIdLists
      data.platIdList = platIdLists
      data.auditUsers = data.auditSts === '0' ? undefined : data.auditUsers
      this.spinLoading = true
      const res = await getCreativeList(data)
      if (res.code === 200 && res.data) {
        const tempData = res.data.items || []
        // 判断是否http https 开头
        // const str = /(http|https):\/\/\S*/
        // const reg = new RegExp(str)
        const newData = tempData.map((item) => {
          item.isChecked = false
          if (item.platName === '直客投放') {
            item.creativeUrl = this.adplanResourceUrl + item.creativeUrl
          }
          item.url = item.creativeUrl
          item.poster = item.creativeUrl
          return item
        })
        this.tableData = newData
      }
      setTimeout(() => {
        this.spinLoading = false
      }, 500)
      this.total = (res.data && res.data.total) || 0
    },
    // 广告样式的下拉
    async getPositionListFn (data) {
      const res = await getPositionList(data)
      const tempData = res.data
      const newData = tempData.map((item) => {
        item.label = item.name
        item.value = item.id
        return item
      })
      this.positionListOptions = newData
      this.positionListAllOptions = [...newData]
    },
    // 广告平台的下拉
    async getPlatformListFn (data) {
      const res = await platformListForCpackage(data)
      if (res.code === 200 && res.data) {
        const tempData = res.data
        const newData = tempData.map((item) => {
          item.label = item.name
          item.value = item.id
          return item
        })
        this.platformOptions = newData
      }
    },
    // 审核人员下拉
    async getAuditUserListFn () {
      const res = await getAuditUserList()
      if (res.code === 200 && res.data) {
        const tempData = res.data
        const newData = tempData.map((item) => {
          item.name = item.username
          return item
        })
        this.auditUserOptions = newData
      }
    },
    // 公司下拉
    async getUserListByCreativeFn (data) {
      const res = await getUserListByCreative(data)
      if (res.code === 200 && res.data) {
        const tempData = res.data
        this.searchFormData.userId = res.data[0].id
        this.userByCreativeOptions = tempData
        this.spinLoading = true
        // 广告平台的下拉
        this.getPlatformListFn(this.searchFormData.userId)
        // 获取素材包列表
        this.getPackageList()
        // 获取素材包筛选项列表
        this.queryData.auditSts === '3' && this.getList(this.searchFormData.userId)
      }
    },
    // 通过审核 拒绝审核
    async auditFn (data) {
      const res = await audit(data)
      if (res.code === 200) {
        this.spinLoading = true
        this.getCreativeListFn({
          ...this.queryData,
          ...this.searchFormData
        })
        // 操作弹框内容
        Object.assign(this, {
          tags: [],
          detailForm: {},
          visible: false,
          inputValue: '',
          searchWord: ''
        })
        // 素材包
        this.getPackageList()
      }
      // this.$message.info(res.msg)
    },
    // 全选
    handleCheckAll () {
      this.tableData = this.tableData.map((item) => {
        item.isChecked = this.allChecked
        return item
      })
    },
    handleCheckTableItem () {
      this.allChecked = this.tableData.every((item) => item.isChecked)
    },
    // 点图片也选中
    CheckTableItem (a) {
      a.isChecked = !a.isChecked
      this.allChecked = this.tableData.every((item) => item.isChecked)
    },
    // 切换等级
    handleChangeRadioItme (e, item) {
      item.isChecked = true
      item.level = e.target.value
    },
    // 审核通过 拒绝审核
    handleChangeAudit (e, temptype, item) {
      let tempData = []
      let newData = []
      if (item) {
        const {
          id: creativeId,
          level,
          description,
          auditUsername,
          title,
          landingPage,
          downloadUrl,
          tags,
          auditSts,
          creativeUrl,
          type,
          uploadTime,
          auditTime,
          platName
        } = item
        const { userId } = this.searchFormData
        switch (temptype) {
          // 单个通过
          case 'batch-item-success':
            this.auditFn({
              userId: (this.getIsAdmin() && userId) || '',
              auditVos: [{ creativeId, level, auditSts: 1 }]
            })
            break
          // 单个拒绝
          case 'batch-item-refuse':
            this.auditFn({
              userId: (this.getIsAdmin() && userId) || '',
              auditVos: [{ creativeId, level, auditSts: 2 }]
            })
            break
          case 'batch-item-detail':
            this.visible = true
            this.detailForm = {
              uploadTime,
              auditTime,
              id: creativeId,
              auditUsername,
              platName,
              description,
              title,
              downloadUrl,
              landingPage,
              tags: tags && tags.length !== 0 && tags.split(','),
              level,
              auditSts,
              type,
              creativeUrl
            }
            if (tags && tags.length > 0) {
              this.tags = tags.split(',')
            }
            break
          default:
            break
        }
      } else {
        const { userId } = this.searchFormData
        switch (temptype) {
          case 'batch-success':
            tempData = this.tableData.filter((item) => item.isChecked && item)
            if (tempData.length === 0) return
            newData = tempData.map((ele) => {
              return { creativeId: ele.id, level: ele.level, auditSts: 1 }
            })
            this.auditFn({ userId: (this.getIsAdmin() && userId) || '', auditVos: newData })
            break
          case 'batch-refuse':
            tempData = this.tableData.filter((item) => item.isChecked && item)
            newData = tempData.map((ele) => {
              return { creativeId: ele.id, level: ele.level, auditSts: 2 }
            })
            this.auditFn({ userId: (this.getIsAdmin() && userId) || '', auditVos: newData })
            break
          case 'batch-success-refuse':
            // 通过的
            tempData = this.tableData.filter((item) => item.isChecked && item)
            newData = tempData.map((ele) => {
              return { creativeId: ele.id, level: ele.level, auditSts: 1 }
            })
            // 拒绝的
            const refuseData = this.tableData.filter((item) => !item.isChecked && item)
            const newRefuseData = refuseData.map((ele) => {
              return { creativeId: ele.id, level: ele.level, auditSts: 2 }
            })
            this.auditFn({ userId: (this.getIsAdmin() && userId) || '', auditVos: [...newData, ...newRefuseData] })
            break
          default:
            break
        }
      }
    },

    // 切换tab
    handleChangeTabs (data) {
      this.queryData.auditSts = data.key
      this.spinLoading = true
      if (+this.queryData.auditSts === 3) {
        // 默认显示一周
        const { endDate, startDate } = this.initDate(6, 0)
        this.searchFormData = {
          ...this.searchFormData,
          beginDate: startDate,
          endDate: endDate
        }
        this.rangePickerDate = [startDate, endDate]
        // 关联素材包列表
        this.getList(this.searchFormData.userId)
        this.$nextTick(() => {
          this.$refs.table.getTabList()
        })
      } else {
        this.getPackageList()
      }
    },
    // 搜索
    handleSerach () {
      this.queryData.page = 1
      this.spinLoading = true
      if (+this.queryData.auditSts === 3) {
        this.$refs.table.getTabList()
      } else {
        this.getPackageList()
      }
    },
    // 确认弹框
    async handleOk () {
      const { id: creativeId, level, auditSts = 0 } = this.detailForm
      const { userId } = this.searchFormData
      // 修改标签接口
      const tags = this.allTags.map((x) => x).join(',')
      const res = await updateTags({
        id: creativeId,
        tags: tags
      })
      if (res.code === 200) {
        this.auditFn({ userId: (this.getIsAdmin() && userId) || '', auditVos: [{ creativeId, level, auditSts }] })
      }
    },
    // 取消弹框
    handleCancel () {
      Object.assign(this, {
        tags: [],
        allTags: [],
        detailForm: { creativeUrl: '' },
        visible: false,
        inputValue: '',
        searchWord: ''
      })
    },
    // input 确认添加
    handleInputConfirm () {
      const inputValue = this.inputValue
        .replace(/,|，|[\r\n]/g, ',')
        .split(',')
        .filter((item) => item && item.trim())
      let tags = this.tags
      if (inputValue && inputValue.length !== 0) {
        tags = [...tags, ...inputValue]
      }
      Object.assign(this, {
        tags,
        allTags: tags,
        inputValue: ''
      })
    },
    // 搜索 tag标签
    // handleTagSerachInput (e) {
    //   const tempValue = e.target.value
    //   if (!tempValue) {
    //     // 没有值得时候
    //     this.tags = this.allTags
    //     return
    //   }
    //   const str = ['', ...tempValue, ''].join('.*')
    //   const reg = new RegExp(str)
    //   this.tags = this.tags.filter((item) => reg.test(item || ''))
    // },
    // 关闭tag
    handleCloseTag (tagItme) {
      this.tags = this.tags.filter((item) => item !== tagItme)
      this.allTags = this.tags
    },
    handleChangeTime (date, dateString) {
      this.rangePickerDate = dateString
      this.searchFormData = {
        ...this.searchFormData,
        beginDate: dateString[0],
        endDate: dateString[1]
      }
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    handleFullscreen (item, index) {
      if (item.type === 1 && this.$refs['previewImg' + index]) {
        this.$refs['previewImg' + index][0].showViewer = true
        document.body.style.overflow = 'hidden'
      } else {
        this.imgModelVisible = true
        this.imgModelData = { type: item.type, url: item.creativeUrl }
      }
    },
    hangleChange (value) {},
    filterOption (input, option) {
      return option.componentOptions.children[0].text.indexOf(input) >= 0
    },
    handleCloseImgModel () {
      this.imgModelVisible = false
      this.imgModelData = {}
    },
    // 分页
    handleCurrentPage (page) {
      this.queryData.page = page
      this.spinLoading = true
      this.getCreativeListFn({
        ...this.queryData,
        ...this.searchFormData
      })
    },
    handleChangePageSize (current, size) {
      this.queryData.page = 1
      this.queryData.limit = size
      this.spinLoading = true
      this.getCreativeListFn({
        ...this.queryData,
        ...this.searchFormData
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';
.m-t-10 {
  margin-top: 10px;
}
.m-b-10 {
  margin-bottom: 10px;
}
.m-b-20 {
  margin-bottom: 20px;
}
.m-b-0 {
  margin-bottom: 0px;
}
.m-r-10 {
  margin-right: 10px;
}
.m-r-20 {
  margin-right: 20px;
}
.position-r {
  position: relative;
}
.custBtnSpan {
  color: @primary-color;
  cursor: pointer;
  padding: 0 15px;
}
.add-btn {
  margin: 0px 10px;
  line-height: 18;
  .ant-btn-primary {
    padding: 5px 10px;
  }
}
.tag-box {
  border: 1px solid #d9d9d9;
  width: 100%;
  height: 240px;
  border-radius: 2px;
  overflow-y: scroll;
  padding: 10px;
  .ant-tag {
    display: block;
    margin-bottom: 10px;
    margin-right: 0px;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    i.anticon-close {
      margin-top: 4px;
    }
  }
}
.font-title {
  font-size: 16px;
  font-weight: 700;
  color: #3d3a3d;
  margin-right: 47px;
}
.text-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text-ellipsis-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* 限制在一个块元素显示的文本的行数 */
  -webkit-box-orient: vertical; /* 垂直排列 */
  word-break: break-all;
}
.grayWord {
  height: 38px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制在一个块元素显示的文本的行数 */
  -webkit-box-orient: vertical; /* 垂直排列 */
  word-break: break-all;
}
// 控制元素显示
.imgItemBox:hover .operationBtn {
  display: block;
}
.pagination {
  margin-bottom: 20px;
}
.imageCoverStyle {
  height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #e7e7e7;
}
.flex-column-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.operationBtn {
  position: absolute;
  background: #f5f5f5;
  width: 100%;
  display: none;
  bottom: 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0 15px;
  z-index: 999;
}
.labelBoxStyle {
  display: flex;
  margin-right: 25px;
  justify-content: center;
  justify-items: center;
}
.ByRefusingBtn {
  background: #ffb736;
  color: #fff;
  border: none;
  ::v-deep .ant-btn:hover {
    background: #ffb736;
    color: #fff;
    border: none;
  }
}

.flexStyle {
  display: flex !important;
}
.addTagStyle {
  background: #3467fe;
  border: 1px dashed #3467fe;
  color: #fff;
}
.originalityApprove {
  padding: 0 10px 10px 10px;
  border-radius: 5px;
  height: 100%;
  .head {
    display: flex;
    background-color: #fff;
    border-radius: 5px 5px 0 0;
    padding: 20px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    .application_top {
      display: flex;
      flex-direction: row;
      .top_item {
        font-size: 14px;
        color: #333333;
        margin: 8px 8px 0;
        padding: 0 14px;
        border-radius: 5px;
        height: 30px;
        line-height: 30px;
        cursor: pointer;
      }
      .act {
        color: @primary-color;
        background: fade(@primary-color, 10%);
      }
    }
  }
  .packageBox {
    margin-bottom: 20px;
    padding: 10px 20px;
    background: #fff;
    border-radius: 5px;
    .adtype_radio_item {
      height: 127px;
      width: 245px;
      margin-right: 20px;
      transition: all 0.8s;
      &:hover {
        transform: scale(1.03);
      }
      &.active {
        color: #fff;
        .adtype_radio_box {
          color: #fff;
          background: @primary-color;
        }
      }
      .adtype_radio_box {
        height: 100%;
        width: 100%;
        border-radius: 10px;
        background: #f6f7fb;
        border-radius: 12px;
        // border: 1px solid #ededed;
        display: flex;
        flex-direction: column;
        padding: 10px;
        margin-right: 5px;
        color: #000000;
        .btnBox {
          flex: 1 1;
          display: flex;
          justify-content: flex-end;
          p {
            width: 40px;
            margin-bottom: 0;
          }
        }
      }
    }
    .packageTitle {
      display: flex;
      justify-content: space-between;
      line-height: 40px;
      cursor: pointer;
      .zidingyi {
        color: @primary-color;
      }
    }
  }
  .fixedButton {
    padding-top: 6px;
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: -10px;
    left: 1px;
    background-color: #fff;
    height: 60px;
    width: 100%;
    box-shadow: 4px 0 10px #0000000a;
    z-index: 999;
    .fixed-content {
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 24px;
    }
  }
  .tableDataBox {
    padding: 20px;
    background: #fff;
    border-radius: 5px;
  }
  .originalitySerachForm {
    flex-wrap: wrap;
    display: flex;
    justify-content: flex-start;
    background: #fff;
    padding: 10px 20px;
    border-radius: 0 0 5px 5px;
    margin-bottom: 20px;
    .dateBox {
      height: 36px;
      line-height: 36px;
      border-radius: 5px;
      padding-left: 10px;
      border: 1px solid #d9d9d9;
      .ant-input {
        outline: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        &:focus {
          outline: none;
        }
      }
    }
  }
  .originalityContent {
    border-radius: 5px;
    margin-bottom: 50px;
    .imgCheckbox {
      position: absolute;
      top: 5px;
      right: 10px;
      z-index: 100;
    }
    .imgfullscreen {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 5px;
      left: 10px;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
      .el-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        /*覆盖掉icon*/
        z-index: 300;
      }
    }
    ::v-deep .ant-tabs-ink-bar {
      width: 40px !important;
      left: 15px;
      border-bottom: 3px solid @primary-color;
    }
    .items {
      video::-webkit-media-controls {
        display: none !important;
      }
      video::-webkit-media-controls-timeline {
        display: none !important;
      }
      video::-webkit-media-controls-current-time-display {
        display: none !important;
      }
      video::-webkit-media-controls-time-remaining-display {
        display: none;
      }
      video::-webkit-media-controls-toggle-closed-captions-button {
        display: none;
      }
      video::-webkit-media-controls-toggle-closed-captions-button {
        display: none;
      }
      video::-webkit-media-controls-volume-slider {
        display: none;
      }
      video::-webkit-media-controls-enclosure {
        overflow: hidden;
      }
      video::-webkit-media-controls-fullscreen-button {
        display: none; //更改是否显示全屏按钮
      }
      video::-webkit-media-controls-mute-button {
        display: none;
      }
      ::v-deep .ant-checkbox-wrapper:hover {
        border: none;
      }
      ::v-deep .ant-checkbox:hover {
        border: none;
      }
      ::v-deep .ant-checkbox-inner {
        background: #536a6e !important;
        border: 1px solid #8ba2a7;
        :hover {
          border: none;
        }
      }
    }
  }
  .ant-empty {
    margin: 0;
    border-radius: 5px;
  }
  ::v-deep.ant-form.ant-form-horizontal .ant-row.ant-form-item .ant-col.ant-form-item-label {
    margin-right: 0 !important;
  }
  ::v-deep {
    .ant-calendar-picker .ant-calendar-picker-input.ant-input {
      border: none;
      height: 32px;
      line-height: 36px;
      padding: 0;
      outline: none !important;
    }
    .ant-input-affix-wrapper .ant-input-suffix {
      top: 35%;
    }
    .ant-radio {
      display: none;
    }
  }
}
::v-deep {
  .ant-modal-body {
    .batchModel {
      padding: 30px;
      display: flex;
      align-items: center;
      .icon {
        color: #fa8b15;
        font-size: 22px;
        margin-right: 20px;
      }
      p {
        margin-bottom: 0;
        line-height: 24px;
      }
    }
  }
}
</style>
