<template>
  <a-modal
    v-model="addAppVisible"
    :title="(isAdd ? '自定义' : '修改') + '素材包'"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    :width="800"
    dialogClass="addapp_modal"
  >
    <template slot="footer">
      <a-button key="back" @click="handleCancel"> 取消 </a-button>
      <a-button key="submit" type="primary" @click="handleSubmit"> 确定 </a-button>
    </template>
    <div class="add_app_content">
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
        <a-form-model-item label="素材包名称" prop="name">
          <a-input
            style="width: 300px"
            v-model.trim="query.name"
            autocomplete="off"
            placeholder="请输入素材包名称"
            :disabled="query.type === 1"
          />
        </a-form-model-item>
        <a-form-model-item label="广告平台" prop="platIdList" v-if="query.type === 3 || isAdd">
          <m-select-more
            class="search_item"
            v-model="query.platIdList"
            :allData="platArr"
            :searchById="true"
            :hasIcon="true"
            style="width: 300px"
            :showId="false"
            width="450px"
            :showLabel="false"
            label="广告平台"
          />
        </a-form-model-item>
        <a-form-model-item label="广告样式" prop="positionIdList" v-if="query.type === 3 || isAdd">
          <m-select-more
            v-if="showPosition"
            class="search_item"
            v-model="query.positionIdList"
            :allData="positionListOptions"
            :searchById="true"
            :hasIcon="true"
            style="width: 300px"
            :showId="false"
            width="450px"
            :showLabel="false"
            label="广告样式"
          />
        </a-form-model-item>
        <a-form-model-item label="绑定审核广告位" prop="placeIdList">
          <div class="group_wrapper">
            <div class="group_box">
              <div class="box_header">
                <span>选择广告位</span>
              </div>
              <div class="box_list">
                <div class="group_item" v-if="groups.length > 0">
                  <el-checkbox
                    style="font-size: 12px"
                    :indeterminate="indeterminate"
                    v-model="checkAll"
                    @change="handleCheckAll"
                  >
                    全部
                  </el-checkbox>
                </div>
                <div
                  :class="{ group_item: true, active: currentGroupId === item.id }"
                  v-for="(item, index) in groups"
                  :key="item.id"
                >
                  <el-checkbox style="font-size: 12px" v-model="item.checked" @change="checkGroupItem(item, index)">
                  </el-checkbox>
                  <span class="group_name">
                    <span class="name">{{ item.name }}</span>
                  </span>
                </div>
                <a-empty style="margin-top: 30px" v-if="groups.length === 0" />
              </div>
            </div>
            <div class="checked_box">
              <div class="box_header">
                <span>已选{{ allTestList.length }}项</span>
              </div>
              <div class="box_list">
                <div :title="item.name" class="checked_item" v-for="(item, index) in allTestList" :key="index">
                  <span class="name">{{ item.name }}</span>
                  <span @click="deleteTestItem(item)" class="delete"><a-icon type="close" /></span>
                </div>
              </div>
            </div>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import { listByAuditSts, platformListForCpackage, addCpackage, updateCpackage } from '@/api/creative'
import { getPositionList } from '@/api/common'
import { mapState } from 'vuex'
export default {
  name: 'MaterialPackage',
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      form: this.$form.createForm(this),
      query: {},
      rules: {
        name: [{ required: true, message: '请输入素材包名称', trigger: 'blur' }],
        platIdList: [{ required: true, message: '请选择广告平台', trigger: 'change' }],
        positionIdList: [{ required: true, message: '请输入广告样式', trigger: 'change' }]
      },
      industry: [],
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      pid: [],
      loading: false,
      showPosition: false,
      mediaAccountList: [],
      groups: [],
      allTestList: [],
      currentGroupId: '',
      currentTestList: [],
      indeterminate: false,
      platformOptions: [],
      positionListOptions: [],
      type: 0
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    appInfo: {
      default: () => ({}),
      type: Object
    },
    platArr: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    groups: {
      handler (val) {
        const arr = []
        val.map((item) => {
          if (item.checked) arr.push(item)
        })
        this.checkAll = arr.length === val.length
        this.indeterminate = arr.length > 0 && arr.length < val.length
        this.allTestList = arr
        this.query.placeIdList = arr.map((item) => item.id)
      },
      deep: true,
      immediate: true
    }
  },
 async created () {
  this.getPositionListFn()
    if (!this.isAdd) {
      this.query = JSON.parse(JSON.stringify(this.appInfo))
      const arr = []
      const arr1 = []
      this.query.positionIdList.map((item) => {
        arr.push(String(item))
      })
      this.query.platIdList.map((item) => {
        arr1.push(String(item))
      })
      this.query.positionIdList = arr
      this.query.platIdList = arr1
      this.type = this.query.type
    }
    this.getListByAuditSts()
    // this.getPlatformListFn()
  },
  mounted () {
  },
  computed: {
    ...mapState({
      token: (state) => state.user.token,
      title: (state) => state.autoweb.title,
      resourceUrl: (state) => state.autoweb.resourceUrl,
      isAdmin: (state) => state.user.isAdmin
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    addAppVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    // 删除单个广告位
    deleteTestItem (val) {
      val.checked = false
      var idx = this.allTestList.findIndex((item) => item.id === val.id)
      this.allTestList.splice(idx, 1)
    },
    // 选择全部广告位
    handleCheckAll (val) {
      this.groups.map((item) => {
        item.checked = val
      })
      this.allTestList = val ? this.groups : []
    },
    // 点击广告位
    checkGroupItem (val, index) {
      var idx = this.allTestList.findIndex((item) => item.id === val.id)
      val.checked ? this.allTestList.push(val) : this.allTestList.splice(idx, 1)
    },
    // 广告样式的下拉
    async getPositionListFn (query) {
      const { data = [] } = await getPositionList(query)
      this.positionListOptions = data.map((item) => ({ name: item.name, id: '' + item.id }))
      this.showPosition = true
    },
    // 广告平台的下拉
    async getPlatformListFn (data) {
      const res = await platformListForCpackage(data)
      if (res.code === 200 && res.data) {
        this.platformOptions = res.data
      }
    },
    // 广告位下拉框
    async getListByAuditSts () {
      const resp = await listByAuditSts()
      if (resp.code === 200) {
        resp.data.map((item) => {
          item.checked = false
        })
        this.groups = resp.data || []
        // 编辑时处理广告位选中
        if (!this.isAdd) {
          this.groups.map((data) => {
            this.query.placeIdList.map((item1) => {
              if (item1 === data.id) data.checked = true
            })
          })
        }
      }
    },
    // 取消
    handleCancel () {
      this.addAppVisible = false
    },
    // 提交
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const platIdLists = []
          const positionIdLists = []
          const { id = '', name = '', placeIdList = [], positionIdList = [], platIdList = [] } = this.query
          positionIdList.map((item) => {
            positionIdLists.push(Number(item))
          })
          platIdList.map((item) => {
            platIdLists.push(Number(item))
          })
          if (this.isAdd) {
            const resp = await addCpackage({
              positionIdList: positionIdLists,
              platIdList: platIdLists,
              placeIdList,
              name
            })
            if (resp.code === 200) {
              this.$message.success('添加成功!')
              this.$emit('changeAppInfo', 'add')
              this.addAppVisible = false
            }
          } else {
            const resp = await updateCpackage({
              positionIdList: positionIdLists,
              platIdList: platIdLists,
              placeIdList,
              name,
              id
            })
            if (resp.code === 200) {
              this.$message.success('修改成功!')
              this.$emit('changeAppInfo', 'update')
              this.addAppVisible = false
            }
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.addapp_modal .ant-modal-body {
  // background-color: #eee;
  .add_app_content {
    height: 65vh;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 7px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      background: fade(@primary-color, 20%);
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px fade(@primary-color, 3%);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
    .group_wrapper {
      user-select: none;
      width: 500px;
      height: 250px;
      display: flex;
      .group_box,
      .checked_box {
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        .box_header {
          font-size: 12px;
          height: 40px;
          line-height: 40px;
          color: #000000;
          background: #f0f0f0;
          border-bottom: 1px solid #e6e6e6;
          padding: 0 10px;
          display: flex;
          justify-content: space-between;
        }
        .box_list {
          flex-grow: 1;
          height: 0;
          padding: 0 5px;
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 7px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            box-shadow: inset 0 0 5px fade(@primary-color, 3%);
            background: fade(@primary-color, 20%);
          }
          &::-webkit-scrollbar-track {
            box-shadow: inset 0 0 5px fade(@primary-color, 3%);
            border-radius: 0;
            background: rgba(0, 0, 0, 0.1);
          }
          .group_item {
            font-size: 12px;
            height: 36px;
            line-height: 36px;
            padding: 0 5px;
            display: flex;
            cursor: pointer;
            .group_name {
              // flex-grow: 1;
              padding-left: 10px;
            }
            &.active {
              color: @primary-color;
            }
            .el-checkbox__input.is-checked + .el-checkbox__label {
              color: #409eff;
            }
          }
          .group_name {
            display: flex;
            justify-content: space-between;
            .name {
              // flex-grow: 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
          .checked_item {
            cursor: pointer;
            height: 36px;
            line-height: 36px;
            margin: 3px 0;
            padding: 0 5px;
            background: fade(@primary-color, 1%);
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            .name {
              flex-grow: 1;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }
          }
        }
      }
      .group_box {
        width: 250px;
        margin-right: 5px;
      }
      .checked_box {
        width: 250px;
        max-width: 250px;
      }
    }
  }
}
</style>
