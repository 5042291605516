import request from '@/utils/request'
const basrUrl = '/creative'
// 审核报表-先审后投
export function preAuditReport (data) {
  return request({
    url: `${basrUrl}/preAuditReport`,
    method: 'post',
    data
  })
}
// 审核报表-先审后投-素材包列表
export function getList (data) {
  return request({
    url: `/c_package/getList`,
    method: 'post',
    data
  })
}

// 素材快照列表
export function creativeCZReport (data) {
  return request({
    url: `/creativeSS/report`,
    method: 'post',
    data
  })
}
